
  import { Component, Vue } from 'vue-property-decorator';
  import InspiniaIbox from '@/components/InspiniaIbox.vue';
  import { FETCH_TRANSACTION, CONFIRM_TRANSACTION, DECLINE_TRANSACTION } from '@/store/transactions';
  import TransactionsUser from '@/components/TransactionsUser.vue';

  @Component({
    components: { TransactionsUser, InspiniaIbox }
  })
  export default class TransactionsDetails extends Vue {
    created() {
      this.$store.dispatch(FETCH_TRANSACTION, this.$route.params.id);
    }

    public confirm(): void {
      this.$store.dispatch(CONFIRM_TRANSACTION)
        .then(() => this.$store.dispatch(FETCH_TRANSACTION, this.$route.params.id))
        .catch((e) => {
          if (e.response && (e.response.data.message || e.response.data.error)) {
            alert(e.response.data.message || e.response.data.error);
          }
        });
    }

    public decline(): void {
      this.$store.dispatch(DECLINE_TRANSACTION)
        .then(() => this.$store.dispatch(FETCH_TRANSACTION, this.$route.params.id));
    }

    get isWithdraw(): boolean {
      return this.$store.state.transactions.item.note === 'Withdrawal';
    }

    get isPending(): boolean {
      return this.isWithdraw && this.$store.state.transactions.item.status === 'pending';
    }

    get incrementBalanceOn(): string | null {
      if (this.isPending) {
        return this.$store.state.transactions.item.amount;
      }

      return null;
    }
  }
