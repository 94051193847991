
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import InspiniaIbox from './InspiniaIbox.vue';
  import { User } from '@/types/entities';

  @Component({
    components: { InspiniaIbox }
  })
  export default class TransactionsUser extends Vue {
    @Prop() title: string;
    @Prop() user: User;
    @Prop() incrementBalanceOn: string | null;
  }
